import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import Chromelpez from "src/components/chromelpez"
import {isIeEdge,isChrome,isFirefox,isSafari} from "@tightrope/lpscripts/browserdetect"
import BelowFold from "src/components/belowFold"
import Disclaimers from "@tightrope/disclaimers"
import RawFooter from "@tightrope/footerlinks/rawfooter.js"


const chromelpezData = import("./data/data.json");
const btfData = import("./data/btfData.json");

export default function prdbest() {

  return(
    <HomepageLayout>
    <Helmet>
     <style type="text/css">{`
       body {
         min-height:100vh;
         width: 100%;
         min-width: 728px;
         min-height: 700px;
         background: #E1EEF8;
         background: linear-gradient(to top right, #E1EEF8, #F7FCFF);
         padding: 0;
         margin: 0;
         background-repeat: no-repeat;
         background-size: cover;
         font-family: 'Roboto', sans-serif;
       }

       #chromelpez-module--chromelpez {
        min-height:100vh;
       }

       .footerlinks-module--br {
         display: none!important;
       }

       .Btf_cb_ez-module--btfCb button.Btf_cb_ez-module--install {
          border: none;
          background: #0078D4;
          color: #fff;
          font-weight: 500;
          font-size: 20pt;
          padding: 15px 50px;
          border-radius: 50px;
          cursor: pointer;
          letter-spacing: .5px;
          transition: .2s ease;
          margin: 25px auto;
          max-width: 300px;
       }

          #disclaimers-module--disclaimers {
      padding: 0;
      font-size: 12px;
      color: #333;
      text-align: center;
      margin: 0 auto !important;
      max-width: 554px;
      font-weight:400;
    }
    #bmpage-module--bmPage p {
      font-size: 12px;
      font-weight:400;
    }
   footer {
    font-family: 'Roboto', sans-serif !important;
    display: block;
    position: relative;
    color: #333;
    padding: 0;
    width: 100%;
    margin:0;
  }
  footer ul {
    position: relative;
    list-style: none;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    color: #333;
    font-size: 12px;
    padding: 12px 0;
  }
  footer ul li {
    padding: 0 4px 0 0;
  }
  footer ul li:last-child {
    padding: 0;
  }
  footer ul li:after {
    content: '|';
    padding: 0 0 0 4px;
  }
  footer ul li:nth-child(6):after, footer ul li:last-child:after {
    content: none;
    padding: 0;
  }
  footer ul a {
    color: #333;
  }

    `}
    </style>
    <style type="text/css">
         </style>
    <title>Print any web page easily - Easy Print</title></Helmet>
    <section>
      <Chromelpez data={chromelpezData}>
      <Disclaimers></Disclaimers>
      </Chromelpez>
      <BelowFold data={btfData}>
        <Disclaimers></Disclaimers>
      </BelowFold>
      <RawFooter></RawFooter>
      </section>
    </HomepageLayout>
  )
}
